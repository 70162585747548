.layout-sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 250px;
    height: 100%;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    @include transition(left $transitionDuration);
    @include shadow(0 0 6px 0 rgba(0, 0, 0, 0.16));

    .layout-logo {
        text-align: center;
        margin-top: 24px;
    }

    .menuitem-badge {
        display: inline-block;
        margin-left: 4px;
        font-size: 10px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        color: $menuitemBadgeColor;
        background-color: $menuitemBadgeBgColor;
        @include border-radius(50%);
    }
}

.sidebarTopLogo {
    width: calc(250px * 0.8);
    margin-left: calc(250px * 0.1);
    margin-top: 2em;
    margin-bottom: 1em;
    background-color: #fff;
    padding: 2em;
    border-radius: 3px;
}

.sidebarFiller {
    flex-grow: 1;
}

.sidebarBottom {
    width: 100%;
    margin-top: 1em;
    text-align: center;

    .sidebarBottomLogo {
        width: calc(250px * 0.7);
    }
    
    .sidebarHotline {
        width: 100%;
        padding: 1em;
        color: $menuitemBadgeColor;
        text-align: initial;
    
        a {
            color: $menuitemDarkActiveColor;
            white-space: nowrap;
        }
    }
}

.layout-menu-container {
    width: 250px;
}