* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 14px;
}

body {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: $fontSize;
    color: $textColor;
    background-color: $bodyBgColor;
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
}

.layout-wrapper {
    padding: 0;
    min-height: 100vh;
}

.column-filter {
    width: 100%;
}

.tableCursorPointer tbody {
    cursor: pointer;
}

.tablePadding tr td {
	padding: 0.2em;
}

.p-datatable-loading-overlay {
	background-color: transparent;
}

.p-dropdown-panel {
	z-index: 9001 !important;
}

.p-fileupload-basic {
    .p-fileupload-choose {
        width: 100%;
    }
}

.black {
    color:white;
    background-color: rgb(255, 0, 0); /* Changing background color */
    border-radius: 20px; /* Making border radius */
    width: auto; /* Making auto-sizable width */
    height: auto; /* Making auto-sizable height */
    padding: 5px 20px 5px 20px; /* Making space around letters */
    font-size: 18px; /* Changing font size */
}
.white {
    color:rgb(255, 255, 255);
    background-color: rgb(0, 255, 21); /* Changing background color */
    border-radius: 20px; /* Making border radius */
    width: auto; /* Making auto-sizable width */
    height: auto; /* Making auto-sizable height */
    padding: 5px 20px 5px 20px; /* Making space around letters */
    font-size: 18px; /* Changing font size */
}
.grey {
    color:rgb(71, 70, 70);
    background-color: rgb(206, 202, 202); /* Changing background color */
    border-radius: 20px; /* Making border radius */
    width: auto; /* Making auto-sizable width */
    height: auto; /* Making auto-sizable height */
    padding: 5px 20px 5px 20px; /* Making space around letters */
    font-size: 18px; /* Changing font size */
}