//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
.p-datatable .p-datatable-tbody > tr > td {
    border: 1px solid #e9ecef;
    padding: 6px;
    border-left-width: 0;
    border-right-width: 0;
}

.p-datatable .p-datatable-thead > tr > th {
    border: 1px solid #e9ecef;
    padding: 6px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
}

.p-datatable-wrapper {
    border-left-width: 1px;
    border-right-width: 1px;
}

::-webkit-scrollbar{

    -webkit-appearance: none;
    width: 7px;

}

::-webkit-scrollbar-thumb {

    border-radius: 4px;
    background-color: rgba(0,0,0,.5); 
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}